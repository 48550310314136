@import '../../theme/theme';

html {
  scroll-snap-type: y mandatory;
}

.read-progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 10px;
  background: $color-accent;
  top: 0;
}

.main {
  background: $color-primary;
}

.about {
  background: $color-secondary;
}

.resume {
  background: $color-tertiary;
}

.projects {
  background: $color-quaternary;
  color: $font-light;
}

.contact {
  background: $color-fifth-gradient;
  color: $font-light;
}

.small-text {
    font-size: 1.5rem !important;
}

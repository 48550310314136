footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    background-image: radial-gradient(
            rgba(0, 0, 0, 0) 1px,
            rgba(0, 0, 0, 0) 1px
    );
    background-size: 4px 4px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    -moz-backdrop-filter: blur(3px);

    font-size: 14px;
    line-height: 14px;
}

footer a {
    text-decoration: none;
}

$font-color: #031F19;
$font-light: #ccede6;

$color-primary: #78edc2;
$color-secondary: #09cb88;
$color-tertiary: #318266;
$color-quaternary: #245d4a;
$color-fifth-gradient: #15362b;
$color-accent: #d63384;
$color-a-link: #3f5f54;

html {
  background: $color-primary;
  color: $font-color;
}

main {
  background: $color-primary;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
}

a {
  color: $color-accent !important;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

a:hover {
  -webkit-mask-image: linear-gradient(-75deg, rgb(234, 143, 185) 30%, #4a1010 50%, rgba(255, 0, 0, 0.6) 70%) !important;
  -webkit-mask-size: 200%;
  animation: shine 2s infinite !important;
}

@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}

.section {
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 1.2rem;

    @media screen and (max-width: 768px) {
      padding: 0 1rem;
    }

    @media screen and (max-width: 480px) {
      padding: 0 0.5rem;
    }

    @media screen and (max-width: 320px) {
      padding: 0 0.1rem;
    }
}

.text {
  padding: 5rem;

  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }

  @media screen and (max-width: 480px) {
    padding: 0 0.5rem;
  }

  @media screen and (max-width: 320px) {
    padding: 0 0.1rem;
  }
}

// LEMONMILK Bold font is used for all headings
@font-face {
  font-family: 'LEMONMILK Bold';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('./fonts/LEMONMILK-Bold.otf') format('opentype');
}

// Feltful font is used for all body text
@font-face {
  font-family: 'Feltful';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('./fonts/feltful_regular.otf') format('opentype');
}

// Repsodent font is used for really cursive style
@font-face {
  font-family: 'Repsodent';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('./fonts/Repsodent.otf') format('opentype');
}

$font-family-heading: 'LEMONMILK Bold', sans-serif;
$font-family-default: 'Feltful', sans-serif;

// Set the font family for headings
h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-heading;
}

h1 {
  font-size: 5rem !important;
}

// Set the font size for heading 2
h2 {
  font-size: 3rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }

  @media (max-width: 320px) {
    font-size: 1rem;
  }
}

// Set the body text font size
p {
  font-family: $font-family-default;
  font-size: 3rem !important;

  @media (max-width: 1200px) {
    font-size: 2.5rem !important;
  }

  @media (max-width: 768px) {
      font-size: 2rem !important;
  }
}

